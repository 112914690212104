<div class="pagination-container" [class.no-margin]="noMargin">
  <button
    (click)="goToPreviousPage()"
    [disabled]="page() === 1"
    class="button primary small"
  >
    <i class="material-symbols-outlined">navigate_before</i>
  </button>

  <input
    type="number"
    [ngModel]="page()"
    (ngModelChange)="onPageInputChange($event)"
    [min]="1"
    [max]="totalPages()"
  />
  <span>of&nbsp;{{ totalPages() }}</span>

  <button
    (click)="goToNextPage()"
    [disabled]="page() === totalPages()"
    class="button primary small"
  >
    <i class="material-symbols-outlined">navigate_next</i>
  </button>

  <fini-tooltip [left]="true">
    <select
      [ngModel]="itemsPerPage()"
      (ngModelChange)="onItemsPerPageChange($event)"
      class="w-auto small"
    >
      <option *ngFor="let option of itemsPerPageOptions" [value]="option">
        {{ option }}
      </option>
    </select>
    <div content>Items&nbsp;per&nbsp;page</div>
  </fini-tooltip>
</div>
